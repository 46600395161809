import React, {useState} from "react";
// reactstrap components
import {Col, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from 'gatsby-image';
// core components

const SmallHeader = (props) => {
    let pageHeader = React.createRef();

    const [background, setBackground] = useState(() => {

        return <Img key={props.backgroundName.id} fluid={props.backgroundName.childImageSharp.fluid}
                    style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>;
    });

    React.useEffect(() => {

        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    return (
        <>
            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    ref={pageHeader}
                >
                    {background}
                </div>
                <div className="content-center">
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <h2 className="title">{props.title}</h2>
                            {(props.hasButton) &&
                            <button>{props.buttonText}</button>
                            }

                            {props.hasButtonStyle &&
                            <div className="btn background-gold">{props.price}</div>
                            }

                            {props.hasLink &&
                            <Link to="/" className="btn btn-gold text-center">Wróć na stronę główną</Link>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

SmallHeader.defaultProps = {
    backgroundName: 'general-page-background.jpg'
};

SmallHeader.propTypes = {
    backgroundName: PropTypes.object,
    title: PropTypes.string
};

export default SmallHeader;
