import React from 'react';

const TitleBlock = (props) => {
    const {title, description, headingLevel, extendedClasses} = props;
    const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const safeHeading = headingLevel ? headingLevel.toLowerCase() : '';
    const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

    return (
        <>
            <div className="heading">
                <Title className={extendedClasses}>{title}</Title>
                {description ? (
                    <p>{description}</p>
                ) : ("")}
            </div>
        </>
    );
}

export default TitleBlock;
