import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "../../assets/img/Logo.svg"
import SelectLanguage from "./SelectLanguage"

const MainNavbar = props => {
  const [colorToSet, setColorToSet] = useState("start")
  const [navbarCollapseOpen, setNavbarCollapseOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 499) {
        setColorToSet(props.navbarStartColor)
      } else if (window.scrollY < 500) {
        setColorToSet(props.navbarEndColor)
      }
    }

    handleScroll()

    return () => {
      document.addEventListener("scroll", handleScroll)
    }
  }, [colorToSet])

  return (
    <>
      {navbarCollapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open")
            setNavbarCollapseOpen(false)
          }}
        />
      ) : null}

      <Navbar className={"fixed-top " + colorToSet} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/en" tag={Link} id="navbar-brand">
              <img src={Logo} alt="Logo Morska 5" width={215} />
            </NavbarBrand>

            <div className="d-flex align-items-center">
              <SelectLanguage
                lang="EN"
                className="d-block d-xl-none languageBlock"
              />
              <button
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open")
                  setNavbarCollapseOpen(!navbarCollapseOpen)
                }}
                aria-expanded={navbarCollapseOpen}
                className="navbar-toggler"
                style={{ marginLeft: "15px" }}
              >
                <span className="navbar-toggler-bar top-bar" />
                <span className="navbar-toggler-bar middle-bar" />
                <span className="navbar-toggler-bar bottom-bar" />
              </button>
            </div>
          </div>
          <Collapse isOpen={navbarCollapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <Link to="/en" className="nav-link">
                  Morska 5 <span className="sr-only">(current)</span>
                </Link>
              </NavItem>

              <NavItem>
                <Link className="nav-link" to="/en/#apartments">
                  Apartments & Rooms
                </Link>
              </NavItem>

              <NavItem>
                <Link className="nav-link" to="/en/#photos">
                  Pictures
                </Link>
              </NavItem>

              <NavItem>
                <Link className="nav-link" to="/en/cennik" lang="">
                  Price list
                </Link>
              </NavItem>

              <NavItem>
                <Link className="nav-link" to="/en/#contact">
                  Contact and directions
                </Link>
              </NavItem>
            </Nav>

            <Nav className="ml-auto" id="social" navbar>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/Morska-5-pokojeapartamenty-102339041559896/"
                  title="Nasz profil na Facebook"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.instagram.com/morska5/"
                  title="Nasz profil na Instagramie"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </NavLink>
              </NavItem>
            </Nav>

            <Nav className="ml-auto" id="social" navbar>
              <NavItem>
                <SelectLanguage lang="EN" className="languageBlock" />
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

MainNavbar.propTypes = {
  navbarStartColor: PropTypes.string,
  navbarEndColor: PropTypes.string,
  navbarCollapseOpen: PropTypes.bool,
}

MainNavbar.defaultProps = {
  navbarStartColor: "start",
  navbarEndColor: "navbar-transparent",
  navbarCollapseOpen: false,
}

export default MainNavbar
